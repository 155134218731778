import { memo, useCallback, useEffect, useState } from "react";

import { Button, Center, Container, Flex, HStack, Spinner, Stack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { ProfileList } from "./ProfileList";
import { useAppStore } from "../../app-store";
import AthleteFilter from "../../components/AthleteFilter";
import { getSports } from "../../services/hca-api";
import { fetchFindAthletesProfiles } from "../../services/search-athlete-api";
import { getAllUniversities } from "../../services/university-api";

const PAGE_SIZE = 20;

type Filters = {
  graduationYear?: string;
  primarySport?: string;
  major?: string;
  readyToWork?: boolean;
}

export const FindAthletesPage = memo(() => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [currentPage, setCurrentPage] = useState(1);

  const [filters, setFilters] = useState<Filters>();
  const [searchValue, setSearchValue] = useState<string>();

  const { data: findAthletesProfiles, isLoading, isFetching, refetch: fetchUserInfo } = useQuery({
    queryKey: ["findAthletesProfiles"],
    queryFn: () =>
      fetchFindAthletesProfiles({ search: searchValue, page: currentPage, pageSize: PAGE_SIZE, ...filters }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });

  const {
    data: sportList,
  } = useQuery({
    queryKey: ["sports"],
    queryFn: getSports,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });

  const {
    data: universityList,
  } = useQuery({
    queryKey: ["universities"],
    queryFn: getAllUniversities,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });

  const handleSearchChange = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  if (!userInfo) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  useEffect(() => {
    if (filters) fetchUserInfo();
  }, [filters, currentPage]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (name: keyof Filters) => (selectedOption: any) => {
    setFilters((prev) => ({
      ...prev,
      graduationYear: prev?.graduationYear,
      primarySport: prev?.primarySport,
      major: prev?.major,
      readyToWork: prev?.readyToWork,
      [name]: selectedOption?.value,
    }));
  };

  const handleSubmit = () => {
    fetchUserInfo();
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  return (
    <Container maxWidth="container.lg" p={[1]} height="100%">
      <VStack gap={5} align={"flex-start"} pt={3} height="100%">
        <Stack height="100%" w="full">
          <Stack direction={["column", "row"]} w="full" align={"flex-start"} display={"flex"} justify={"flex-end"}>

            <AthleteFilter
              sportList={sportList}
              onFilterChange={(name: string) => handleFilterChange(name as keyof Filters)} // Change type to string
              handleSearchChange={handleSearchChange}
              handleSubmit={handleSubmit}
            />
          </Stack>
          <Flex direction="column" width="100%" height="100%" gap={2}>
            <Text fontSize="sm">
              {findAthletesProfiles?.totalResults} Athletes Found
            </Text>

            <ProfileList
              profiles={findAthletesProfiles?.users || []}
              hasNextPage={(findAthletesProfiles && findAthletesProfiles?.totalPages > findAthletesProfiles?.currentPage) || false}
              isFetching={isFetching}
              isFetchingNextPage={isFetching}
              universityList={universityList}
            // fetchNextPage={fetchNextPage}
            />
          </Flex>


          <HStack mb={4} mt={4} justifyContent="space-between">
            <Flex justifyContent="flex-start" width="full" mt={4} gap={2}>
              <Text fontSize="sm">
                {findAthletesProfiles?.totalResults} Athletes Found
              </Text>
              <Text fontSize="sm">
                (Page {currentPage} of {findAthletesProfiles?.totalPages})
              </Text>
            </Flex>

            <Flex justifyContent="flex-end" width="full" gap={4}>
              <Button onClick={handlePreviousPage} isDisabled={currentPage === 1 || isFetching || isLoading}>
                Previous
              </Button>
              <Button onClick={handleNextPage} isDisabled={currentPage === findAthletesProfiles?.totalPages || isFetching || isLoading}>
                Next
              </Button>
            </Flex>
          </HStack>

        </Stack>
      </VStack>
    </Container>
  );
});
