import axios from "axios";

import { AppConfig } from "./app-config-service";
import { EmployerProfileInfo } from "../../../../shared/employerModels";

export const getEmployerProfile = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/employer/profile`);
  return data as EmployerProfileInfo;
};

export const postEmployerProfile = async (profileInfo: EmployerProfileInfo) => {
  return await axios.post(`${AppConfig.apiUrl}/api/employer/profile`, profileInfo, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export const getSpecificEmployerProfile = async (userId: number) => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/employer/profile/${userId}`);
  return data as EmployerProfileInfo;
};