import { useState } from "react";

import {
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { UserRole } from "@shared/models";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { Select as ReactSelect } from "chakra-react-select";

import { getAllUniversities } from "../../services/university-api";
import { getUsers, postNewUserEmailWithRole, updateUserEmailWithRole } from "../../services/user-info-api";

export const UpdateUserRole = () => {
  const queryClient = new QueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<UserRole | null>(null);
  const [selectedUniversity, setSelectedUniversity] = useState<string | null>(null);
  const [isAddingNewUser, setIsAddingNewUser] = useState(true);
  const toast = useToast();

  const { data: users } = useQuery({
    queryKey: ["allUsers"],
    queryFn: getUsers,
  });

  const {
    data: universities,
  } = useQuery({
    queryKey: ["universities"],
    queryFn: getAllUniversities,
  });

  const { mutate: createNewUserRoleByEmail, isPending: pendingNewUserRoleByEmail } = useMutation({
    mutationFn: postNewUserEmailWithRole,
    onSuccess() {
      toast({ title: "Success: User Role Created", status: "success", position: "top", duration: 2000 });
      queryClient.invalidateQueries({ queryKey: ['allUsers'], exact: true });
      setEmail("");
      setRole(null);
      setSelectedUniversity(null);
    },
    onError(error: unknown) {
      console.error("error", error);
      toast({
        title: "Error!",
        description: "There was an issue calling the server",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
  });

  const { mutate: updateUserRoleByEmail, isPending: pendingUpdateUserRoleByEmail } = useMutation({
    mutationFn: updateUserEmailWithRole,
    onSuccess() {
      toast({ title: "Success: User Role Updated", status: "success", position: "top", duration: 2000 });
      queryClient.invalidateQueries({ queryKey: ['allUsers'], exact: true });
      setEmail("");
      setRole(null);
      setSelectedUniversity(null);
    },
    onError(error: unknown) {
      console.error("error", error);
      toast({
        title: "Error!",
        description: "There was an issue calling the server",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
  });

  const checkAgainstExistingUsers = () => {
    if (!email) return;
    const existingUser = users?.find(user => user.emailAddress === email);
    if (existingUser) {

      return toast({
        title: "Error!",
        description: "User already exists! Please update User role instead.",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
  };

  const handleAddUser = () => {
    if (!role) {
      return toast({
        title: "Error!",
        description: "Please select a role",
        status: "error",
        position: "top",
        duration: 3000
      });
    }
    const universityId = Number(selectedUniversity) ?? null;
    if (isAddingNewUser) {
      createNewUserRoleByEmail({ email, role, universityId });
    } else {
      updateUserRoleByEmail({ email, role, universityId });
    }
    setIsOpen(false);
  };

  const argumentsAreInvalid = (): boolean => {
    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!emailIsValid || !role) return true;
    if (email && isAddingNewUser && users?.find(user => user.emailAddress === email)) return true;
    return false;
  };

  const openModal = (newUser: boolean) => {
    setIsAddingNewUser(newUser);
    setIsOpen(true);
    setEmail("");
    setRole(null);
    setSelectedUniversity(null);
  }

  return (
    <>
      <HStack justifyContent={"space-around"} alignItems={"center"}>
        <Button size={["sm", "md"]} variant="outline" colorScheme="brand" onClick={() => openModal(true)} isLoading={pendingNewUserRoleByEmail}>Add New User Role</Button>
        <Button size={["sm", "md"]} variant="outline" colorScheme="brand" onClick={() => openModal(false)} isLoading={pendingUpdateUserRoleByEmail}>Update User Role</Button>
      </HStack>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isAddingNewUser ? "Add New User Role" : "Update User Role"}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody style={{ padding: "24px" }}>
            {!isAddingNewUser && (
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <ReactSelect
                  placeholder="Select user"
                  onChange={(option) => {
                    const selectedUser = users?.find(user => user.emailAddress === option?.value);
                    if (selectedUser) {
                      setEmail(selectedUser?.emailAddress ?? "");
                      setRole(selectedUser.role);
                      setSelectedUniversity(selectedUser?.primaryUniversityId?.toString() ?? null);
                    }
                  }}
                  options={users?.map(user => ({ value: user.emailAddress, label: user.emailAddress }))}
                />
              </FormControl>
            )}
            {
              isAddingNewUser && (
                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={checkAgainstExistingUsers} />
                </FormControl>
              )
            }

            <FormControl isRequired mt={4}>
              <FormLabel>Role</FormLabel>
              <ReactSelect
                placeholder="Select role"
                value={role ? { value: role, label: role } : null}
                onChange={(option) => setRole(option?.value as UserRole)}
                options={Object.values(UserRole).map((role) => ({ value: role, label: role }))}
              />
            </FormControl>

            {(role === UserRole.UNIVERSITY || role === UserRole.ADMIN) && (
              <FormControl isRequired mt={4}>
                <FormLabel>University Access</FormLabel>
                <ReactSelect
                  placeholder="Select university"
                  value={selectedUniversity ? { value: selectedUniversity, label: universities?.find(university => university.id === Number(selectedUniversity))?.universityName ?? "" } : null}
                  onChange={(option) => setSelectedUniversity(option?.value ?? null)}
                  options={universities?.map((university) => ({ value: university.id.toString(), label: university.universityName ?? "" }))}
                />
              </FormControl>
            )}
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddUser} isDisabled={argumentsAreInvalid()}>
              {isAddingNewUser ? "Add User" : "Update User"}
            </Button>
            <Button variant="ghost" onClick={() => setIsOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};