import { useState } from "react";

import { Button, Center, Container, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";

import { EmployerProfileInfo } from '../../../../../shared/employerModels'
import EmployerAboutCard from "../../components/cards/EmployerAboutCard";
import EmployerProfileCard from "../../components/cards/EmployerProfileCard";
import DynamicForm from "../../components/DynamicForm";
import EditButtonWrapper from "../../components/EditButtonWrapper";
import FileTile from "../../components/tiles/FileTile";
import ProfileCompletionTile from "../../components/tiles/ProfileCompletionTile";
import { editAboutInfo, editGeneralInfo, EmployerProfileKeysArray, empoloyerDashboardDynamicFormConfig } from "../../config/dashboardFormConfigs";
import { getEmployerProfile, postEmployerProfile } from "../../services/employer-api";
import { getProfileImage } from "../../services/file-api";

export function EmployerDashboardPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const formMethods = useForm<EmployerProfileInfo>();
  const [fieldsToEdit, setFieldsToEdit] = useState<EmployerProfileKeysArray | null>(null)
  const [formConfigData] = useState(empoloyerDashboardDynamicFormConfig);

  const { data: profileData, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profileData"],
    queryFn: getEmployerProfile,
    refetchOnWindowFocus: "always"
  });

  const { data: profileImage, isLoading: isLoadingProfileImage } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { mutate: updateProfile, isPending: updatingProfile } = useMutation({
    mutationFn: postEmployerProfile,
    onSuccess() {
      toast({ title: "Saved", status: "success", position: "top", duration: 1000 });
      onClose();
      queryClient.invalidateQueries({ queryKey: ['profileData'], exact: true });
    },
    onError(error) {
      console.error("error", error);
      toast({
        title: "Error!",
        description: "There was an issue calling the server",
        status: "error",
        position: "top",
        duration: 3000
      });
      onClose();
    }
  });

  const handleSubmit: SubmitHandler<EmployerProfileInfo> = (data: EmployerProfileInfo) => {
    updateProfile(data);
  };

  const openEditModal = (selectedFieldsToEdit: EmployerProfileKeysArray) => {
    setFieldsToEdit(selectedFieldsToEdit);
    onOpen();
  };

  if (isProfileLoading || !profileData || isLoadingProfileImage) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  // const brandColors = [
  //   "brand.700",
  //   "brand.primary",
  //   "brand.accent"
  // ];

  // const getRandomBrandColor = () => {
  //   const randomIndex = Math.floor(Math.random() * brandColors.length);
  //   return brandColors[randomIndex];
  // };

  return (
    <Container maxWidth="container.xl" p={1} pb={9}>
      <Stack direction={["column", "row-reverse"]} gap={3}>
        <VStack w={["full", "380px"]} gap={3} marginLeft={[0, 3]}>

          <ProfileCompletionTile
            profileType="employer"
            profileData={profileData}
            onEditField={(fields: string[]) => openEditModal(fields as unknown as EmployerProfileKeysArray)}
          />

          <FileTile profileType="employer" />

          {/* Hiding below because the urls are causing automation test errors - due to the fake urls */}
          {/* <Card w="full">
            <CardHeader bg={"blackAlpha.300"} color={"blackAlpha.800"} roundedTop={"md"}>
              <Heading size={'md'}>Candidate Watch</Heading>
            </CardHeader>
            <CardBody>
              <VStack spacing={3} align="stretch">
                <HStack>
                  <Avatar size="md" name="Emily Doe" src="https://example.com/john-doe.jpg" bg={getRandomBrandColor()} color={"white"} />
                  <Text flex="1">Emily Doe</Text>
                  <Badge colorScheme="green">Next</Badge>
                </HStack>
                <HStack>
                  <Avatar size="md" name="Jessica Smith" src="https://example.com/jane-smith.jpg" bg={getRandomBrandColor()} color={"white"} />
                  <Text flex="1">Jessica Smith</Text>
                  <Badge colorScheme="green">Next</Badge>
                </HStack>
                <HStack>
                  <Avatar size="md" name="Sarah Johnson" src="https://example.com/bob-johnson.jpg" bg={getRandomBrandColor()} color={"white"} />
                  <Text flex="1">Sarah Johnson</Text>
                  <Badge colorScheme="yellow">Wait</Badge>
                </HStack>
              </VStack>
            </CardBody>
          </Card> */}
        </VStack>

        <VStack w="100%" gap={3}>
          <EditButtonWrapper onEdit={() => openEditModal(editGeneralInfo)}>
            <EmployerProfileCard editMode profileData={profileData!} profileImage={profileImage} />
          </EditButtonWrapper>

          <EditButtonWrapper onEdit={() => openEditModal(editAboutInfo)}>
            <EmployerAboutCard profileData={profileData!} />
          </EditButtonWrapper>

        </VStack>
      </Stack >

      <Modal onClose={onClose} size={["full", "xl"]} isOpen={isOpen} closeOnOverlayClick={false} scrollBehavior={"inside"}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Profile Info</ModalHeader>
            <Divider />
            <ModalCloseButton />

            <ModalBody>
              <DynamicForm
                fieldsToEdit={fieldsToEdit as unknown as (keyof EmployerProfileInfo)[] ?? []}
                formData={profileData!}
                formMethods={formMethods}
                formConfig={{ ...formConfigData }}
              />
            </ModalBody>

            <Divider />
            <ModalFooter>
              <Button colorScheme='brand' mr={3} type="submit" isLoading={updatingProfile} loadingText='Saving'>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>

    </Container >
  );
}