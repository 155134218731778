import { useRef, useEffect } from "react";

import { Box, Center, Spinner, VStack } from "@chakra-ui/react";

import EmployerProfilePreviewCard from "./EmployerProfilePreviewCard";
import { EmployerProfileSearchResult } from "../../../../../shared/employerModels";

interface EmployerProfilesProps {
    profiles: EmployerProfileSearchResult[];
    hasNextPage: boolean;
    isFetching: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage?: () => void;
}

export const EmployerProfiles: React.FC<EmployerProfilesProps> = ({
    profiles,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    fetchNextPage
}) => {
    const parentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage && fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage, profiles.length, isFetchingNextPage]);

    useEffect(() => {
        parentRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }, [profiles]);

    if (isFetching && !isFetchingNextPage) {
        return (
            <Box>
                <Center h="100%">
                    <Spinner size={"lg"} />
                </Center>
            </Box>
        );
    }

    return (
        <Box flexGrow="1" flexShrink="1" overflowY="auto" height="0px" ref={parentRef} style={{ overflowAnchor: "none" }}>
            <VStack spacing={[2, 4]}>
                {profiles.map((profile, index) => (
                    <EmployerProfilePreviewCard key={index} profileData={profile} profileImage={profile.profileImageUrl ?? null} />
                ))}
                {hasNextPage && fetchNextPage && (
                    <div>Loading more...</div>
                )}
            </VStack>
        </Box>
    );
};