import { memo, useEffect, useState } from "react";

import { Box, Center, Container, Flex, Spinner, Stack, Text, VStack, Table, Thead, Tbody, Tr, Th, Td, TableCaption, Avatar, Button, HStack, Badge, Tag, Tooltip, IconButton } from "@chakra-ui/react";
import { Gender, UserProfileSearchResult } from "@shared/models";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { TbBrandLinkedin, TbFileText, TbSend } from "react-icons/tb";

import { useAppStore } from "../app-store";
import AthleteFilter from "../components/AthleteFilter";
import { useUniversity } from "../hooks/useUniversity";
import { getSports } from "../services/hca-api";
import { fetchSearchProfiles } from "../services/search-athlete-api";

const PAGE_SIZE = 20;

type Filters = {
  graduationYear: string;
  primarySport: string;
  major: string;
  readyToWork: boolean;
  gender: Gender;
  hidePendingAthletes: boolean;
}

const tagAccentColorList = ["FF686B", "00FF9D", "0070F9", "EE964B"];

export const UniversityDashboardPage = memo(() => {
  const [userInfo] = useAppStore((state) => [state.userInfo]);
  const [searchValue, setSearchValue] = useState<string>();
  const [filters, setFilters] = useState<Filters | Record<string, never>>({});
  const [allProfiles, setAllProfiles] = useState<UserProfileSearchResult[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [universityId, setUniversityId] = useState<number | null>(null);

  const {
    data,
    refetch: fetchUserInfo,
    isFetching,
    // hasNextPage,
    // hasPreviousPage,
    // isFetchingNextPage,
    // fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [],
    queryFn: () =>
      fetchSearchProfiles({ search: searchValue, page: currentPage, pageSize: PAGE_SIZE, ...filters }),
    getNextPageParam: (lastPage, group, lastPageParam) => {
      return lastPage.totalPages > lastPage.currentPage ? lastPageParam + 1 : undefined;
    },
    initialPageParam: 1,
    refetchOnWindowFocus: false
  });

  const {
    data: sportList,
  } = useQuery({
    queryKey: ["sports"],
    queryFn: getSports,
  });

  const { university } = useUniversity(universityId);

  useEffect(() => {
    const profiles = data?.pages?.[0].users;

    if (profiles) {
      setAllProfiles(profiles);
      setUniversityId(profiles[0]?.universityId ?? null);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (filters) fetchUserInfo();
  }, [filters, currentPage]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (name: keyof Filters) => (selectedOption: any) => {
    setFilters((prev) => ({
      ...prev,
      graduationYear: prev?.graduationYear,
      primarySport: prev?.primarySport,
      major: prev?.major,
      readyToWork: prev?.readyToWork,
      gender: prev?.gender,
      hidePendingAthletes: prev?.hidePendingAthletes,
      [name]: selectedOption?.value,
    }));
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSubmit = () => {
    fetchUserInfo();
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  if (!userInfo) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  const totalPages = data?.pages?.[0]?.totalPages || 1;

  return (
    <Container maxWidth={["container.lg", "90%"]} p={[1]} height="100%">
      <VStack align={"flex-start"}>
        <Text fontSize="xl" fontWeight="bold">{university?.universityName}</Text>

        <Stack w="full">
          <Flex direction="column" width="100%" height="100%" gap={[3, 8]}>
            <AthleteFilter
              sportList={sportList}
              onFilterChange={(name: string) => handleFilterChange(name as keyof Filters)} // Change type to string
              handleSearchChange={handleSearchChange}
              handleSubmit={handleSubmit}
              showGenderFilter
              showUnclaimedAthletesFilter
            />

            {!isFetching && (
              <VStack align="flex-start">
                <HStack m={0}>
                  <Text fontSize="sm">
                    {data?.pages?.[0]?.totalResults} Athletes
                  </Text>
                  <Text fontSize="sm">
                    (Page {currentPage} of {totalPages})
                  </Text>
                </HStack>
                {(!filters.hidePendingAthletes) && <Text fontSize="xs" color="gray.500">
                  {data?.pages?.[0]?.totalResultsPendingLogin} Unclaimed Athletes
                </Text>}
              </VStack>
            )}

            <Table variant="simple" colorScheme='brand' size={['sm', 'md']} mb={16} overflowX="auto">
              {!isFetching && allProfiles.length === 0 && <TableCaption>0 Athletes Found</TableCaption>}
              <Thead>
                <Tr>
                  <Th>Athlete Name</Th>
                  <Th isNumeric>Graduation</Th>
                  <Th>Sport</Th>
                  <Th>Major</Th>
                  <Th>Preferred Location</Th>
                  <Th isNumeric>GPA</Th>

                  <Th><Tooltip label="Resume | LinkedIn | Email | Ready to Work">Quick Actions</Tooltip></Th>
                </Tr>
              </Thead>
              <Tbody>
                {!isFetching && allProfiles.map((profile) => (
                  <Tr key={profile.id}>
                    <Td>
                      <Box gap={2} display="flex" justifySelf="center" alignItems="center">
                        <Avatar
                          size="sm"
                          src={profile.profileImageUrl}
                          alignSelf="center"
                          justifySelf="center"
                        />
                        {profile.displayName}
                        {profile.pendingLogin && <Badge ml='1' colorScheme='red'>
                          Login Required
                        </Badge>}
                      </Box>
                    </Td>
                    <Td isNumeric>{profile.graduationYear || "-"}</Td>
                    <Td>{profile.primarySport || "-"}</Td>
                    <Td>{profile.major || "-"}</Td>
                    <Td width="250px">
                      {profile.preferredLocation.length > 0 ? (
                        <Box display="flex" flexWrap="wrap" gap={2} maxWidth="100%" position="relative">
                          {profile.preferredLocation.slice(0, 2).map((location, index) => (
                            <Tag p={1} bg={`#${tagAccentColorList[index % tagAccentColorList.length]}`} key={location} mb={1}>
                              {location}
                            </Tag>
                          ))}
                          {profile.preferredLocation.length > 2 && (
                            <Tooltip
                              label={profile.preferredLocation.join(', ')}
                              shouldWrapChildren
                              aria-label="All preferred locations"
                              placement="right-end"
                            >
                              <Tag p={1} bg='gray.300' key={'more'} mb={1} px={2}>
                                +{profile.preferredLocation.length - 2}
                              </Tag>
                            </Tooltip>
                          )}
                        </Box>
                      ) : "-"}
                    </Td>
                    <Td isNumeric>{profile.gpa || "-"}</Td>
                    <Td>
                      <Box display="flex" gap={4} alignItems="center">
                        <IconButton
                          icon={<TbFileText fontSize={32} />}
                          aria-label="Resume"
                          isDisabled={!profile.resumeUrl}
                          variant="ghost"
                          color={profile.resumeUrl ? "blue.500" : "gray.300"}
                          onClick={() => {
                            if (profile.resumeUrl) {
                              window.open(profile.resumeUrl, '_blank', 'noopener,noreferrer');
                            }
                          }}
                        />
                        <IconButton
                          as="a"
                          icon={<TbBrandLinkedin fontSize={32} />}
                          aria-label="LinkedIn profile"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={profile.linkedInUrl ? (profile.linkedInUrl.startsWith('http') ? profile.linkedInUrl : `https://${profile.linkedInUrl}`) : undefined}
                          isDisabled={!profile.linkedInUrl}
                          variant="ghost"
                          color={profile.linkedInUrl ? "blue.500" : "gray.300"}
                        />
                        <IconButton
                          as="a"
                          icon={<TbSend fontSize={30} />}
                          aria-label="Email"
                          href={`mailto:${profile.emailAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          isDisabled={!profile.emailAddress}
                          variant="ghost"
                          color={profile.emailAddress ? "blue.500" : "gray.500"}
                        />
                        {profile.readyToWork ? <BsCheck2Circle fontSize={32} style={{ color: "green" }} /> : <BsCircle fontSize={30} style={{ color: "red" }} />}
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {isFetching && <Spinner alignSelf="center" />}
            {!isFetching && (
              <HStack mb={16} justifyContent="space-between">
                <Flex justifyContent="flex-start" width="full" mt={4} gap={2}>
                  <Text fontSize="sm">
                    {data?.pages?.[0]?.totalResults} Athletes Found
                  </Text>
                  <Text fontSize="sm">
                    (Page {currentPage} of {totalPages})
                  </Text>
                </Flex>

                <Flex justifyContent="flex-end" width="full" gap={4}>
                  <Button onClick={handlePreviousPage} isDisabled={currentPage === 1}>
                    Previous
                  </Button>
                  <Button onClick={handleNextPage} isDisabled={currentPage === totalPages}>
                    Next
                  </Button>
                </Flex>
              </HStack>
            )}

          </Flex>
        </Stack>
      </VStack>
    </Container>
  );
});