import { useEffect, useState } from "react";

import { Box, Button, Card, CardHeader, Center, Container, Divider, Flex, Heading, Hide, HStack, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, Stepper, StepSeparator, StepStatus, StepTitle, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { AchievementItem, RoutePath, UserProfileInfo } from "@shared/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoMdCreate } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

import { AiBulletListModal } from "./AiBulletListModal";
import { PendingAthletePage } from "./PendingAthletePage";
import AthleteAboutCard from "../../components/cards/AthleteAboutCard";
import AthleteAcademicCard from "../../components/cards/AthleteAcademicCard";
import AthleteAthleticCard from "../../components/cards/AthleteAthleticCard";
import AthleteCareerCard from "../../components/cards/AthleteCareerCard";
import AthleteHcaCard from "../../components/cards/AthleteHcaCard";
import AthletePreferred from "../../components/cards/AthletePreferred";
import AthleteProfileCard from "../../components/cards/AthleteProfileCard";
import DynamicForm from "../../components/DynamicForm";
import EditButtonWrapper from "../../components/EditButtonWrapper";
import FileTile from "../../components/tiles/FileTile";
import ProfileCompletionTile from "../../components/tiles/ProfileCompletionTile";
import VideoTile from "../../components/tiles/VideoTile";
import {
  athleteDashboardDynamicFormConfig,
  editUserGeneralInfo,
  editUserHcaTaglineInfo,
  editUserAboutInfo,
  editUserAthleticInfo,
  editUserAcademicInfo,
  editUserCareerInfo,
  editUserWorkPreference,
  UserProfileKeysArray,
  UserProfileToEdit,
  newAthleteStep1,
  newAthleteStep2,
  newAthleteStep3,
} from "../../config/dashboardFormConfigs";
import { getHighlightBullets } from "../../services/ai-conversation-api";
import { getPendingProfilesByDomain, getProfile, postUserProfile } from "../../services/athlete-api";
import { getProfileImage } from "../../services/file-api";
import { getAchievements, getPositionBySportId, getSports } from "../../services/hca-api";
import { getAllUniversities } from "../../services/university-api";
import { calculateProfileCompletion } from "../../utils";

export function AthleteDashboardPage() {
  const { user } = useUser();
  const router = useNavigate();
  const [showLoading, setShowLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const formMethods = useForm<UserProfileInfo>();
  const [fieldsToEdit, setFieldsToEdit] = useState<UserProfileKeysArray | null>(null)
  const [formConfigData, setFormConfigData] = useState(athleteDashboardDynamicFormConfig);
  const [highlightsModalOpen, setHighlightsModalOpen] = useState(false);
  const [openAiModal, setOpenAiModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [newUser, setNewUser] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showPendingAthletePage, setShowPendingAthletePage] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formValues = formMethods.watch();

  const { data: profileData, isLoading: isProfileLoading, isFetching: isFetchingProfile } = useQuery({
    queryKey: ["profileData"],
    queryFn: getProfile,
    refetchOnWindowFocus: "always"
  });


  const newUserSteps = [
    { title: 'First', description: 'About You', fieldsToEdit: newAthleteStep1 },
    { title: 'Second', description: 'Athletic Info', fieldsToEdit: newAthleteStep2 },
    { title: 'Third', description: 'Work Preferences', fieldsToEdit: newAthleteStep3 },
    // { title: 'Fourth', description: 'Career Info', fieldsToEdit: newAthleteStep4 },
  ]

  const { data: pendingProfiles, isFetching: isFetchingPendingProfiles, refetch: fetchPendingProfiles, isSuccess: isSuccessPendingProfiles } = useQuery({
    queryKey: ["pendingProfiles"],
    queryFn: () => {
      const domain = user?.primaryEmailAddress?.emailAddress?.split("@")[1];
      return getPendingProfilesByDomain(domain);
    },
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  // We check every user for whether they have completed more than 50% so we trigger the pending search less often (hopefully only once)
  useEffect(() => {
    if (profileData) {
      if (profileData.displayName) {
        setShowLoading(false);
      } else {
        fetchPendingProfiles();
      }
    }
  }, [profileData])

  // This should only run when we have successfully fetched the pending profiles
  useEffect(() => {
    if (!isSuccessPendingProfiles) return;

    // if we have pending profiles, we show the pending page.
    if (pendingProfiles && isSuccessPendingProfiles && pendingProfiles?.length) {
      setShowPendingAthletePage(true);

      // if we don't have a pending record, this is actually just a new user that we haven't created a profile for.
    } else {
      setNewUser(true);
      onOpen();
    }

    setTimeout(() => {
      setShowLoading(false);
    }, 400);
  }, [pendingProfiles, isSuccessPendingProfiles])

  const {
    data: universityList,
    isLoading: isLoadingUniversities,
    isFetching: isFetchingUniversities
  } = useQuery({
    queryKey: ["universities"],
    queryFn: getAllUniversities,
  });

  const { data: athletesHighlightBullets } = useQuery({
    queryKey: ["highlightBullets"],
    queryFn: getHighlightBullets,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { data: profileImage, isLoading: isLoadingProfileImage, isFetching: isFetchingProfileImage } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { data: achievements, isLoading: isLoadingAchievements, isFetching: isFetchingAchievements } = useQuery({
    queryKey: ["achievements"],
    queryFn: getAchievements,
  });

  const {
    data: sportList,
    isLoading: isLoadingSportOptions,
    isFetching: isFetchingSportOptions
  } = useQuery({
    queryKey: ["sports"],
    queryFn: getSports,
  });


  useEffect(() => {
    let tempFormConfigData = formConfigData;
    if (sportList) {
      if (formConfigData?.primarySport?.options && formConfigData?.primarySport?.options?.length > 0) return
      tempFormConfigData = {
        ...tempFormConfigData,
        primarySport: {
          ...tempFormConfigData.primarySport,
          options: sportList?.map((item) => (item.sport)),
        }
      }
    }
    setFormConfigData(tempFormConfigData)
  }, [sportList, isLoadingSportOptions, isFetchingSportOptions, formConfigData])

  useEffect(() => {
    let tempFormConfigData = formConfigData;
    if (achievements) {
      if (formConfigData?.athleticAchievements?.options && formConfigData?.athleticAchievements?.options?.length > 0) return
      tempFormConfigData = {
        ...tempFormConfigData,
        athleticAchievements: {
          ...tempFormConfigData.athleticAchievements,
          options: achievements?.map((achievement) => (achievement.achievement)),
        }
      }
    }
    setFormConfigData(tempFormConfigData)
  }, [achievements, isLoadingAchievements, isFetchingAchievements, formConfigData])

  useEffect(() => {
    let tempFormConfigData = formConfigData;
    if (universityList) {
      if (formConfigData?.universityId?.options && formConfigData?.universityId?.options?.length > 0) return
      tempFormConfigData = {
        ...tempFormConfigData,
        universityId: {
          ...tempFormConfigData.universityId,
          options: universityList?.map((university) => ({ id: university?.id.toString(), name: university?.universityName ?? "" })),
        }
      }
    }
    setFormConfigData(tempFormConfigData)
  }, [formConfigData, universityList?.length, isLoadingAchievements, universityList, isLoadingUniversities, isFetchingUniversities])

  // refetch positions based on selected sport
  useQuery({
    queryKey: ["positions"],
    queryFn: async () => {
      if (!profileData?.primarySport && !formValues.primarySport) return [];
      const id = sportList?.find((item) => item.sport === (formValues.primarySport ?? profileData?.primarySport))?.id;
      if (id === undefined) return [];
      const positionsData = await getPositionBySportId(id);
      setFormConfigData({
        ...formConfigData,
        primaryPosition: {
          ...formConfigData.primaryPosition,
          options: positionsData,
        }
      })
      return positionsData;
    },
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['positions'], exact: true });
  }, [formValues.primarySport, profileData]);

  const { mutate: updateProfile, isPending: updatingProfile } = useMutation({
    mutationFn: postUserProfile,
    onSuccess() {
      toast({ title: "Saved", status: "success", position: "top", duration: 1000 });
      if (!newUser) {
        onClose();
      }
      queryClient.invalidateQueries({ queryKey: ['profileData'], exact: true });
    },
    onError(error) {
      console.error("error", error);
      toast({
        title: "Error!",
        description: "There was an issue calling the server",
        status: "error",
        position: "top",
        duration: 3000
      });
      onClose();
    }
  });

  const handleSubmit: SubmitHandler<UserProfileInfo> = (data) => {
    const updatedAchievements = data.athleticAchievements.map((achievement) => {
      const foundAchievement = achievements?.find((a) => a.achievement === achievement.achievement);
      return {
        ...achievement,
        id: foundAchievement ? foundAchievement.id : null,
      };
    });

    // There are certain fields that we need to manage before sending to the backend because we incrementally save the profile during the onboarding process.
    const profileInfo: UserProfileInfo = {
      ...data,
      athleticExperience: data?.athleticExperience ? Number(data?.athleticExperience) : 0,
      professionalExperience: data?.professionalExperience ? Number(data?.professionalExperience) : 0,
      graduationYear: data?.graduationYear ? Number(data?.graduationYear) : 0,
      phoneNumber: data?.phoneNumber ? data?.phoneNumber?.replace(/\D/g, '') : "",
      universityId: data?.universityId ? Number(data?.universityId) : null,
      athleticAchievements: updatedAchievements as AchievementItem[],
    };

    updateProfile(profileInfo);
  };

  const handleClose = () => {
    setShowPendingAthletePage(false);
    setTimeout(() => {
      setNewUser(true);
      onOpen();
    }, 200);
  }

  const LoadingSpinner = () => {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  if (
    (
      showLoading ||
      !profileData ||
      isProfileLoading ||
      isFetchingProfile ||
      isLoadingAchievements ||
      isLoadingSportOptions ||
      isLoadingProfileImage ||
      isLoadingUniversities ||
      isFetchingUniversities ||
      isFetchingProfileImage ||
      isFetchingPendingProfiles
    ) && !newUser) {
    return <LoadingSpinner />;
  }

  if (showLoading) return <LoadingSpinner />;

  const openEditModal = (selectedFieldsToEdit: UserProfileKeysArray) => {
    setHighlightsModalOpen(false)
    setFieldsToEdit(selectedFieldsToEdit);
    onOpen();
  };

  const openHighlightsModal = (selectedFieldsToEdit: UserProfileKeysArray) => {
    setHighlightsModalOpen(true)
    setFieldsToEdit(selectedFieldsToEdit);
    onOpen();
  };

  const noHighlightsSelected = formValues?.highlightBullets ? formValues?.highlightBullets?.length === 0 : true;
  const noHighlightsCreated = athletesHighlightBullets?.length === 0;
  const profileCompletion = calculateProfileCompletion(profileData, "athlete");


  const newAthleteSaveAndContinue = () => {
    if (activeStep >= newUserSteps.length - 1) {
      setNewUser(false);
      onClose();
    } else {
      setActiveStep(activeStep + 1);
      onOpen();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter' && !(event.target instanceof HTMLButtonElement || (event.target instanceof HTMLInputElement && event.target.type === 'submit'))) {
      event.preventDefault();
    }
  };


  if (showPendingAthletePage && !showLoading) {
    return <PendingAthletePage onClose={handleClose} />;
  }

  if (profileData && !showPendingAthletePage && !showLoading) return (
    <Container maxWidth="container.xl" p={1} pb={9}>
      <Stack direction={["column", "row-reverse"]} gap={3}>
        <VStack w={["full", "380px"]} gap={3} marginLeft={[0, 3]} hidden={isMobile}>
          <ProfileCompletionTile
            profileType="athlete"
            profileData={profileData}
            onEditField={(fields: string[]) => openEditModal(fields as unknown as UserProfileKeysArray)}
          />

          <VideoTile thumbnailUrl={"steph.png"} videoUrl={"https://www.youtube.com/watch?v=KxB62peJNw4?cc_load_policy=1"} alt={"AI Coach"} />

          <FileTile profileType="athlete" />

          <Card size={["lg"]} w="full" colorScheme="brand" as='button' _hover={{ boxShadow: "lg", bg: "brand.600" }} bg="brand.primary" alignItems={"center"} py={[4, 10]}
            onClick={() => router(RoutePath.COACH)}>
            <HStack h="100%">
              <Icon
                color={"white"}
                fontSize="3rem"
                as={IoMdCreate}
                mr="4"
              />
              <VStack gap={0} mb={2} color={"white"}>
                <Text>Chat with our</Text>
                <Heading fontSize={"1.5rem"}>AI Coach</Heading>
              </VStack>
            </HStack>
          </Card>
        </VStack>

        <VStack w="100%" gap={3}>
          <EditButtonWrapper onEdit={() => openEditModal(editUserGeneralInfo)}>
            <AthleteProfileCard profileData={profileData} profileImage={profileImage} />
          </EditButtonWrapper>

          <VStack w={["full", "380px"]} gap={3} marginLeft={[0, 3]} hidden={!isMobile}>
            <ProfileCompletionTile
              profileType="athlete"
              profileData={profileData}
              onEditField={(fields: string[]) => openEditModal(fields as unknown as UserProfileKeysArray)}
            />

            <VideoTile thumbnailUrl={"steph.png"} videoUrl={"https://www.youtube.com/watch?v=KxB62peJNw4?cc_load_policy=1"} alt={"AI Coach"} />

            <FileTile profileType="athlete" />

            <Card size={["lg"]} w="full" colorScheme="brand" as='button' _hover={{ boxShadow: "lg", bg: "brand.600" }} bg="brand.primary" alignItems={"center"} py={[4, 10]}
              onClick={onOpen}>
              <Link to={RoutePath.COACH}>
                <HStack h="100%">
                  <Icon
                    color={"white"}
                    fontSize="3rem"
                    as={IoMdCreate}
                    mr="4"
                  />
                  <VStack gap={0} mb={2} color={"white"}>
                    <Text>Chat with our</Text>
                    <Heading fontSize={"1.5rem"}>AI Coach</Heading>
                  </VStack>
                </HStack>
              </Link>
            </Card>
          </VStack>

          {profileCompletion < 50 && (
            <Card size="lg" w="full" bg="yellow.300">
              <CardHeader>
                <Heading fontSize="lg" textAlign="center">Complete at least 50% of your profile so recruiters can find you!</Heading>
              </CardHeader>
            </Card>
          )}

          <EditButtonWrapper onEdit={() => openEditModal(editUserHcaTaglineInfo)}>
            <AthleteHcaCard profileData={profileData} isSoftened />
          </EditButtonWrapper>

          <EditButtonWrapper onEdit={() => openHighlightsModal(editUserAboutInfo)}>
            <AthleteAboutCard editMode profileData={profileData} />
          </EditButtonWrapper>

          <EditButtonWrapper onEdit={() => openEditModal(editUserAthleticInfo)}>
            <AthleteAthleticCard profileData={profileData} />
          </EditButtonWrapper>

          <EditButtonWrapper onEdit={() => openEditModal(editUserAcademicInfo)}>
            <AthleteAcademicCard profileData={profileData} />
          </EditButtonWrapper>

          <EditButtonWrapper onEdit={() => openEditModal(editUserCareerInfo)}>
            <AthleteCareerCard editMode profileData={profileData} />
          </EditButtonWrapper>

          <EditButtonWrapper onEdit={() => openEditModal(editUserWorkPreference)}>
            <AthletePreferred profileData={profileData} />
          </EditButtonWrapper>
        </VStack>
      </Stack>

      <Modal onClose={onClose} size={["full", "3xl"]} isOpen={isOpen} closeOnOverlayClick={false} scrollBehavior={"inside"}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)} onKeyDown={handleKeyDown}>
          <ModalOverlay />
          {!newUser && (
            <ModalContent>
              <ModalHeader>Edit Profile Info</ModalHeader>
              <Divider />
              <ModalCloseButton />

              <ModalBody>
                <DynamicForm
                  fieldsToEdit={fieldsToEdit as unknown as (keyof UserProfileToEdit)[] ?? []}
                  formData={profileData}
                  formMethods={formMethods}
                  formConfig={{ ...formConfigData }}
                />
                {highlightsModalOpen && (
                  <>
                    <Flex flexDirection={["column", "row"]} gap={[4, 6]} width="100%">
                      {noHighlightsSelected && !noHighlightsCreated && <Text>Select highlights to display your Highlight Reel!</Text>}
                    </Flex>
                    <Flex justifyContent="start" width="100%">
                      {athletesHighlightBullets && (
                        <AiBulletListModal
                          isOpen={openAiModal}
                          onClose={() => setOpenAiModal(false)}
                          highlights={athletesHighlightBullets}
                          onSave={(selectedHighlights) => {
                            formMethods.setValue("highlightBullets", selectedHighlights);
                            setOpenAiModal(false);
                            queryClient.invalidateQueries({ queryKey: ['highlightBullets'], exact: true });
                          }}
                        />
                      )}
                      {noHighlightsCreated ? (
                        <Link to={RoutePath.COACH}>
                          <Text color="brand.primary">Chat with your AI Coach to build your Highlight Reel!</Text>
                        </Link>
                      ) : (
                        <Button variant={"secondary"} onClick={() => setOpenAiModal(true)}>
                          Review/Update Highlights
                        </Button>
                      )}
                    </Flex>
                  </>
                )}
              </ModalBody>

              <Divider />
              <ModalFooter>
                <Button colorScheme='brand' mr={3} type="submit" isLoading={updatingProfile} loadingText='Saving'>
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          )}

          {newUser && (
            <ModalContent>
              <ModalHeader>Welcome to Her Competitive Advantage!</ModalHeader>
              <Divider />

              <ModalBody>
                <Stepper size={["sm", "md"]} index={activeStep} colorScheme='brand'>
                  {newUserSteps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>

                      <Hide below={activeStep === index ? "0px" : "md"}>
                        <Box flexShrink='0'>
                          <StepTitle>{step.title}</StepTitle>
                          <StepDescription>{step.description}</StepDescription>
                        </Box>
                      </Hide>

                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>

                <Text mt={4} style={{ fontWeight: "bold" }}>Let's get your profile started.</Text>
                <Text as="i">(You can always edit it later.)</Text>

                <DynamicForm
                  fieldsToEdit={newUserSteps[activeStep]?.fieldsToEdit as unknown as (keyof UserProfileToEdit)[] ?? []}
                  formData={profileData}
                  formMethods={formMethods}
                  formConfig={{ ...formConfigData }}
                  ignoreRequiredFields
                />
              </ModalBody>

              <Divider />
              <ModalFooter>
                {activeStep > 0 && (
                  <Button
                    variant={"secondary"}
                    onClick={() => setActiveStep(activeStep - 1)}
                    type={"button"}
                    mr={3}
                  >
                    Back
                  </Button>
                )}
                <Button
                  colorScheme='brand'
                  onClick={newAthleteSaveAndContinue}
                  type={"submit"}
                  isLoading={updatingProfile}
                  loadingText='Saving'
                >
                  {activeStep >= newUserSteps.length - 1 ? "Done" : "Save Progress and Continue"}
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </form>
      </Modal>
    </Container>

  );
}