import axios from "axios";

import { AppConfig } from "./app-config-service";
import { EmployerProfileSearchResult } from "../../../../shared/employerModels";

export const fetchFindEmployersProfiles = async (
  filters: { search?: string; page?: number; pageSize?: number } = {}
): Promise<{ employers: EmployerProfileSearchResult[], totalResults: number, totalResultsPendingLogin: number, currentPage: number, totalPages: number }> => {
  const response = await axios.get(`${AppConfig.apiUrl}/api/search/find-employers`, {
    params: { ...filters }
  });

  return response.data;
};