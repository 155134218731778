import { Center, Spinner, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from 'react-router-dom';

import { getSpecificEmployerProfile } from "../../services/employer-api";
import { getSpecificProfileImage } from "../../services/file-api";

import { EmployerProfile } from ".";

export function SpecificEmployerProfilePage() {
    const { userId } = useParams();
    const { data: profileData, isFetching: isProfileFetching, isError: isProfileError } = useQuery({
        queryKey: ["specificEmployerProfileData"],
        queryFn: () => getSpecificEmployerProfile(Number(userId)),
        refetchOnWindowFocus: "always",
    });

    const { data: profileImage, isFetching: isProfileImageFetching } = useQuery({
        queryKey: ["specificEmployerProfileImage"],
        queryFn: () => getSpecificProfileImage(Number(userId)),
        refetchOnMount: true,
    });

    if (isProfileError) {
        return (
            <Center h="100%">
                <Text color="red.500" fontSize="2xl" fontWeight="bold">User does not exist</Text>
            </Center>
        );
    }

    if (!profileData || isProfileFetching || isProfileImageFetching) {
        return (
            <Center h="100%">
                <Spinner size={"lg"} />
            </Center>
        );
    }



    return (
        <EmployerProfile profileData={profileData} profileImage={profileImage} />
    );
}