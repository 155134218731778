import { EmployerProfileInfo } from "@shared/employerModels";

import EmployerAboutCard from "../../components/cards/EmployerAboutCard";
import EmployerProfileCard from "../../components/cards/EmployerProfileCard";
import { Page } from "../../components/Page";


type EmployerProfileProps = {
    profileData: EmployerProfileInfo;
    profileImage: { url: string } | null;
};

export function EmployerProfile({ profileData, profileImage }: EmployerProfileProps) {
    return (
        <Page>
            <EmployerProfileCard profileData={profileData} profileImage={profileImage} />

            <EmployerAboutCard profileData={profileData} />
        </Page>
    );
}