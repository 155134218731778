import { Center, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { getProfile } from "../../services/athlete-api";
import { getProfileImage } from "../../services/file-api";

import { AthleteProfile } from ".";

export function AthleteProfilePage() {
  const { data: profileData, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profileData"],
    queryFn: getProfile,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  const { data: profileImage } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false
  });

  if (isProfileLoading || !profileData) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return (
    <AthleteProfile profileData={profileData} profileImage={profileImage} />
  );
}