import axios from "axios";

import { AppConfig } from "./app-config-service";
import { UserProfileInfo } from "../../../../shared/models";
import { HCAInsights } from "../pages/admin";

export const getAllAthleteProfiles = async () => {
  const { data } = await axios.get(`${AppConfig.apiUrl}/api/profiles`);

  return data as UserProfileInfo[] | undefined;
};

export const getHCAInsights = async () => {
  const { data: universityAthleteCounts } = await axios.get(`${AppConfig.apiUrl}/api/admin/university-athlete-counts`);

  return { universityAthleteCounts: universityAthleteCounts } as HCAInsights;
};
