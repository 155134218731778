import { Center, Spinner } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { Page } from "../../components/Page";
import { getEmployerProfile } from "../../services/employer-api";
import { getProfileImage } from "../../services/file-api";

import { EmployerProfile } from ".";

export function EmployerProfilePage() {
  const { data: profileData, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profileData"],
    queryFn: getEmployerProfile,
    refetchOnWindowFocus: "always"
  });

  const { data: profileImage, isLoading: isLoadingProfileImage } = useQuery({
    queryKey: ["profileImage"],
    queryFn: getProfileImage,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });

  if (isProfileLoading || !profileData || isLoadingProfileImage) {
    return (
      <Center h="100%">
        <Spinner size={"lg"} />
      </Center>
    );
  }

  return (
    <Page>
      <EmployerProfile profileData={profileData} profileImage={profileImage} />
    </Page>
  );
}