import { memo, useState } from "react";

import { Button, Center, Container, Flex, Heading, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { EmployerProfiles } from "./EmployerProfiles";
import { fetchFindEmployersProfiles } from "../../services/search-employer-api";

const PAGE_SIZE = 20;

export const FindEmployersPage = memo(() => {
    const [currentPage, setCurrentPage] = useState(1);

    const { data: employersData, isLoading: isEmployersLoading } = useQuery({
        queryKey: ["employers"],
        queryFn: () => fetchFindEmployersProfiles({ page: currentPage, pageSize: PAGE_SIZE }),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
    });

    const handleNextPage = () => {
        setCurrentPage((prev) => prev + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    if (isEmployersLoading || !employersData) {
        return (
            <Center h="100%">
                <Spinner size={"lg"} />
            </Center>
        );
    }

    if (employersData.employers.length === 0) {
        return (
            <Center h="100%">
                <Heading>No employers found</Heading>
            </Center>
        );
    }

    return (
        <Container maxWidth="container.lg" p={[1]} height="100%">
            <VStack gap={5} align={"flex-start"} pt={3} height="100%">
                <Heading>Find Employers</Heading>
                <EmployerProfiles
                    profiles={employersData.employers}
                    hasNextPage={employersData.totalPages > currentPage}
                    isFetching={isEmployersLoading}
                    isFetchingNextPage={isEmployersLoading}
                    fetchNextPage={handleNextPage}
                />
                <HStack w="full" mb={4} mt={4} px={4} justifyContent="space-between">
                    <Flex justifyContent="flex-start" width="full" mt={4} gap={2}>
                        <Text fontSize="sm">
                            {employersData.totalResults} Employers Found
                        </Text>
                        <Text fontSize="sm">
                            (Page {currentPage} of {employersData.totalPages})
                        </Text>
                    </Flex>

                    <Flex justifyContent="flex-end" width="full" gap={4}>
                        <Button onClick={handlePreviousPage} isDisabled={currentPage === 1 || isEmployersLoading}>
                            Previous
                        </Button>
                        <Button onClick={handleNextPage} isDisabled={currentPage === employersData.totalPages || isEmployersLoading}>
                            Next
                        </Button>
                    </Flex>
                </HStack>
            </VStack>
        </Container>
    );
});
