import locationData from "@shared/locationData.json";
import majorsData from "@shared/majorsData.json";

import { EmployerProfileInfo } from "../../../../shared/employerModels";
import { AchievementItem, University, UserProfileInfo } from "../../../../shared/models";
import { FormFieldConfig, FormFieldType } from "../components/DynamicForm";


// =========================================
// Athlete Profile Form Configuration
// =========================================
// This section defines the form configuration for the athlete profile.
// It includes field definitions, validation rules, and UI settings
// for various aspects of an athlete's profile information.

export type UserProfileToEdit = Omit<UserProfileInfo, "id" | "userId" | "gender">;
export type UserProfileKeysArray = [keyof UserProfileToEdit][];

export const editUserGeneralInfo: UserProfileKeysArray = ["displayName", "readyToWork", "phoneNumber", "linkedInUrl", "currentLocation"] as unknown as UserProfileKeysArray;
export const editUserHcaTaglineInfo: UserProfileKeysArray = ["hcaPersonalTagline"] as unknown as UserProfileKeysArray;
export const editUserAboutInfo: UserProfileKeysArray = ["aboutMe"] as unknown as UserProfileKeysArray;
export const editUserCareerInfo: UserProfileKeysArray = ["currentTitle", "professionalExperience"] as unknown as UserProfileKeysArray;
export const editUserWorkPreference: UserProfileKeysArray = ["preferredWorkEnvironment", "preferredEmployment", "preferredLocation",] as unknown as UserProfileKeysArray;
export const editUserAthleticInfo: UserProfileKeysArray = ["primarySport", "primaryPosition", "athleticExperience", "athleticLevel", "athleticExperience", "athleticAchievements"] as unknown as UserProfileKeysArray;
export const editUserAcademicInfo: UserProfileKeysArray = ["educationLevel", "universityId", "major", "graduationYear", "gpa"] as unknown as UserProfileKeysArray;

// Welcome new Athlete to HCA
export const newAthleteStep1AboutYou_old: UserProfileKeysArray = ["displayName", "personalEmail", "currentLocation", "readyToWork", "phoneNumber", "aboutMe"] as unknown as UserProfileKeysArray;
export const newAthleteStep2AcademicInfo_old: UserProfileKeysArray = ["educationLevel", "universityId", "major", "graduationYear", "gpa"] as unknown as UserProfileKeysArray;
export const newAthleteStep3CareerInfo_old: UserProfileKeysArray = ["currentTitle", "professionalExperience", "preferredWorkEnvironment", "preferredEmployment", "preferredLocation"] as unknown as UserProfileKeysArray;
export const newAthleteStep4AthleticInfo_old: UserProfileKeysArray = ["primarySport", "primaryPosition", "athleticExperience", "athleticLevel", "athleticExperience", "athleticAchievements"] as unknown as UserProfileKeysArray;

export const newAthleteStep1: UserProfileKeysArray = ["displayName", "personalEmail", "phoneNumber", "currentLocation", "universityId", "major", "gpa", "graduationYear"] as unknown as UserProfileKeysArray;
export const newAthleteStep2: UserProfileKeysArray = ["primarySport", "primaryPosition", "athleticLevel", "athleticExperience"] as unknown as UserProfileKeysArray;
export const newAthleteStep3: UserProfileKeysArray = ["educationLevel", "preferredWorkEnvironment", "preferredEmployment", "preferredLocation", "readyToWork"] as unknown as UserProfileKeysArray;
// export const newAthleteStep4: UserProfileKeysArray = ["currentTitle", "professionalExperience"] as unknown as UserProfileKeysArray;

const stateOptions = locationData.locations.sort((a, b) => a.localeCompare(b)) as string[];
const preferredLocationOptions = ["Any", ...stateOptions];
const athleticLevelOptions = ["High School", "College", "Professional"];
const preferredEmploymentOptions = ["Full time", "Part time", "Contract", "Internship"];
const preferredWorkEnvironmentOptions = ["Any", "In-person", "Remote", "Hybrid"];
const educationLevelOptions = ["High School", "Some College", "Associate's", "Bachelor's", "Master's", "Doctorate"];
const majorOptions = majorsData.majors.sort((a, b) => a.localeCompare(b));

export const athleteDashboardDynamicFormConfig: Record<keyof UserProfileInfo, FormFieldConfig<UserProfileInfo[keyof UserProfileInfo]>> = {
  id: { label: "ID", helperText: "Your unique ID", type: FormFieldType.TEXT, required: true },
  userId: { label: "User ID", helperText: "Your user ID", type: FormFieldType.TEXT, required: true },
  gender: { label: "Gender", helperText: "Your gender", type: FormFieldType.SELECT, options: ["Male", "Female", "Other"], required: true },
  displayName: { label: "Display Name", tooltip: "Your first and last name", helperText: "Your first and last name", type: FormFieldType.TEXT, required: true },
  personalEmail: { label: "Personal Email", helperText: "If you signed up with a university email", type: FormFieldType.TEXT, required: true },
  phoneNumber: { label: "Phone Number", helperText: "###-###-####", type: FormFieldType.TEXT, required: true },
  hcaPersonalTagline: { label: "HCA Tagline -  What makes you stand out?", helperText: "A personal tagline about you", type: FormFieldType.TEXT },
  linkedInUrl: { label: "LinkedIn URL", helperText: "Your LinkedIn profile link", type: FormFieldType.TEXT },
  primarySport: { label: "Primary Sport", helperText: "Your main sport", type: FormFieldType.SELECT, options: [], required: true },
  primaryPosition: { label: "Primary Position", helperText: "Select a Primary Sport", type: FormFieldType.SELECT, options: [], required: true },
  otherSport: { label: "Other Sport", helperText: "Any other sport you play", type: FormFieldType.TEXT },
  otherPosition: { label: "Other Position", helperText: "Position in the other sport", type: FormFieldType.TEXT },
  athleticLevel: { label: "Athletic Level", helperText: "Your athletic level", type: FormFieldType.SELECT, options: athleticLevelOptions },
  athleticExperience: { label: "Athletic Experience", helperText: "Years at the highest level", type: FormFieldType.NUMBER },
  athleticAchievements: { label: "Athletic Achievements", helperText: "Your achievements in sports", type: FormFieldType.MULTISELECT, options: [], accessor: (data: AchievementItem) => data.achievement, dataShapeToSave: (data: string) => ({ achievement: data }) },
  educationLevel: { label: "Education Level", helperText: "Your highest level of education", type: FormFieldType.SELECT, options: educationLevelOptions, required: true },
  universityId: { label: "University Name", tooltip: "Current university or where you graduated from", helperText: "Name of the university you attended", type: FormFieldType.SELECT, options: [], required: true, accessor: (data: University | null) => data?.universityName ?? "" },
  major: { label: "Major", helperText: "Your major in university", type: FormFieldType.SELECT, options: majorOptions, required: true },
  graduationYear: { label: "Graduation Year", helperText: "Or expected graduation year", type: FormFieldType.NUMBER },
  gpa: { label: "GPA", helperText: "Your GPA", type: FormFieldType.NUMBER, required: true },
  currentTitle: { label: "Current Title", helperText: "Your current job title", type: FormFieldType.TEXT },
  professionalExperience: { label: "Professional Experience", helperText: "Number of years of professional experience", type: FormFieldType.NUMBER },
  preferredWorkEnvironment: { label: "Preferred Work Environment", helperText: "Your preferred work environment", type: FormFieldType.SELECT, options: preferredWorkEnvironmentOptions },
  preferredEmployment: { label: "Preferred Employment", helperText: "Your preferred employment type", type: FormFieldType.SELECT, options: preferredEmploymentOptions },
  currentLocation: { label: "Current Location", helperText: "Your current location", type: FormFieldType.SELECT, options: stateOptions },
  preferredLocation: { label: "Preferred Location", helperText: "Your preferred work location", type: FormFieldType.MULTISELECT, options: preferredLocationOptions },
  readyToWork: { label: "Ready to Work", helperText: "Are you ready to start working?", type: FormFieldType.CHECKBOX },
  aboutMe: { label: "About Me", helperText: "A short description about yourself", type: FormFieldType.TEXTAREA },
  highlightBullets: { label: "Highlight Bullets", helperText: "Key points about you", type: FormFieldType.TEXTAREA },
};


// =========================================
// Employer Profile Form Configuration
// =========================================
// This section defines the form configuration for the employer profile.
// It includes field definitions, validation rules, and UI settings
// for various aspects of an employer's profile information.
export type EmployerProfileKeysArray = [keyof EmployerProfileInfo][];

export const editGeneralInfo: EmployerProfileKeysArray = ["employerName", "url", "tagline", "highlight", "location", "industry"] as unknown as EmployerProfileKeysArray;
export const editAboutInfo: EmployerProfileKeysArray = ["aboutUs"] as unknown as EmployerProfileKeysArray;

export const empoloyerDashboardDynamicFormConfig: Record<keyof EmployerProfileInfo, FormFieldConfig<EmployerProfileInfo[keyof EmployerProfileInfo]>> = {
  companyName: { label: "Comapny Name", helperText: "The company name", type: FormFieldType.TEXT, required: true, },
  websiteUrl: { label: "Comapny Website", helperText: "Full url", type: FormFieldType.TEXT, required: true, },
  tagline: { label: "Company Tagline", helperText: "What do you do in 120 characters or less?", type: FormFieldType.TEXT, required: true, },
  highlight: { label: "Company Highlight", helperText: "What are you proud of?", type: FormFieldType.TEXT, required: true, },
  location: { label: "Location", helperText: "Where are you headquartered", type: FormFieldType.TEXT, required: true, },
  industry: { label: "Industry", helperText: "What industry?", type: FormFieldType.TEXT, required: true, },
  aboutUs: { label: "About", helperText: "Tell us about you, breifly", type: FormFieldType.TEXTAREA, required: true, }
};