import React from 'react';

import { CheckCircleIcon, EditIcon } from "@chakra-ui/icons";
import { Card, CardHeader, CardBody, Heading, HStack, CircularProgress, CircularProgressLabel, Button, Text, VStack } from "@chakra-ui/react";

import { EmployerProfileInfo } from '../../../../../shared/employerModels';
import { UserProfileInfo } from '../../../../../shared/models';
import { calculateProfileCompletion, getEmptyProfileFields } from '../../utils';

interface ProfileCompletionTileProps {
    profileType: "athlete" | "employer";
    profileData: UserProfileInfo | EmployerProfileInfo;
    onEditField: (fields: string[]) => void;
}

const ProfileCompletionTile: React.FC<ProfileCompletionTileProps> = ({ profileType, profileData, onEditField }) => {
    const [showMoreFields, setShowMoreFields] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);
    const [listMaxCount, setListMaxCount] = React.useState(4);

    React.useEffect(() => {
        setIsMobile(window.innerWidth < 768);
    }, []);

    React.useEffect(() => {
        if (isMobile) {
            setListMaxCount(2);
        } else {
            setListMaxCount(4);
        }
    }, [isMobile]);

    let completionColor = "brand.300";

    const profileCompletion = calculateProfileCompletion(profileData, profileType);
    const missingFields = getEmptyProfileFields(profileData, profileType);

    if (profileCompletion < 40) {
        completionColor = "red.400";
    } else if (profileCompletion < 80) {
        completionColor = "yellow.400";
    }

    const ProfileComplete = () => {
        return (
            <Card size="lg" w="full">
                <CardHeader paddingY={4} w="full">
                    <HStack w="full" justifyContent={"space-between"}>
                        <CheckCircleIcon color="green.500" boxSize={10} />
                        <Heading textAlign={'center'} fontWeight="bold" size={["sm", "md"]} color="green.500">Profile Complete!</Heading>
                    </HStack>
                </CardHeader>
            </Card>
        );
    }

    if (profileCompletion === 100) {
        return <ProfileComplete />
    }

    return (
        <Card size={["md", "lg"]} w="full">
            <CardHeader paddingTop={2} paddingBottom={2} w="full">
                <HStack w="full" justifyContent={"space-between"}>
                    <Heading size={["md"]}>Next Steps</Heading>
                    <CircularProgress value={profileCompletion} color={completionColor} capIsRound>
                        <CircularProgressLabel fontSize="xs" as='b'>{profileCompletion}%</CircularProgressLabel>
                    </CircularProgress>
                </HStack>
            </CardHeader>

            <CardBody paddingTop={0}>
                <VStack spacing={2} align="stretch">
                    {missingFields.slice(0, showMoreFields ? undefined : listMaxCount).map((missingField) => (
                        <Button
                            onClick={() => onEditField([missingField.field])}
                            key={missingField.field}
                            w="full"
                            rightIcon={<EditIcon boxSize={4} />}
                            colorScheme='brand'
                            variant='outline'
                            justifyContent="space-between"
                        >
                            <Text fontSize="sm" noOfLines={1}>{missingField.label}</Text>
                        </Button>
                    ))}
                    {missingFields.length > listMaxCount && (
                        <Button
                            onClick={() => setShowMoreFields(!showMoreFields)}
                            w="full"
                            colorScheme='accent'
                            variant='outline'
                            justifyContent="center"
                        >
                            <Text fontSize="sm">{showMoreFields ? "Show less" : `+${missingFields.length - listMaxCount} more`}</Text>
                        </Button>
                    )}
                </VStack>
            </CardBody>
        </Card>
    );
};

export default ProfileCompletionTile;
